<template>
  <v-container>
    <v-card 
      class="mx-auto my-12"
      max-width="574"
    >
      <v-card-title>
          Text-to-Speech Synthesis 
      </v-card-title>
      <v-card-text>
        <v-textarea 
          v-model="entrada"
          label="Texto"
          outlined
        >
        </v-textarea>
      </v-card-text>
      <v-card-actions>
        <v-btn id="convert" @click="handleInput">Ler</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import AWS from 'aws-sdk';
// set up aws
AWS.config.region = 'sa-east-1';
AWS.config.credentials = new AWS.Credentials('AKIAYX3YKDAGJQ2CFKO7','03X4CV+Skj9TFbjKaYHev6tQ6yQhKmupNbKbid7H');
const polly = new AWS.Polly();

const speechParams = {
    OutputFormat: "mp3", // For example, 'mp3'
    SampleRate: "16000", // For example, '16000
    Text: "teste de voz", // The 'speakText' function supplies this value
    TextType: "text", // For example, "text"
    VoiceId: "Vitoria" // For example, "Matthew"
};
export default {
  name:'LeitoTexto',
  components: {},
  data:()=>({
    entrada:'testes de voz'
  }),
  mounted() {
  },
  computed: {
  },
  methods: {
    handleInput() {
      speechParams.Text = this.entrada
      polly.synthesizeSpeech(speechParams, (err, data) => {
        if (err) {
          console.log(err, err.stack); // an error occurred
          //alert("Error calling Amazon Polly. " + err.message);
        }
        else {
          const uInt8Array = new Uint8Array(data.AudioStream);
          const arrayBuffer = uInt8Array.buffer;
          const blob = new Blob([arrayBuffer]);
          const url = URL.createObjectURL(blob);
          const audioElement = new Audio([url]);
          audioElement.play();
        }
      });
    }
  }
}
</script>
